.cssload-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cssload-container:before {
  content: "";
  width: 100%;
  height: 50%;
  z-index: -1;
  -o-transition: top 1.38s linear 3.57s;
  transition: top 1.38s linear 3.57s;
  position: absolute;
  top: 0;
  left: 0;
}

.cssload-container:after {
  content: "";
  width: 100%;
  height: 50%;
  z-index: -1;
  -o-transition: bottom 1.38s linear 3.57s;
  transition: bottom 1.38s linear 3.57s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cssload-container.done:before {
  top: -50%;
}

.cssload-container.done:after {
  bottom: -50%;
}

.cssload-progress {
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.cssload-progress .cssload-progress-item {
  text-align: center;
  width: 97px;
  height: 97px;
  border: 2px solid #1bbce8;
  border-radius: 50%;
  line-height: 97px;
}

.cssload-progress .cssload-progress-item:before {
  content: "";
  width: 44px;
  height: 44px;
  transform-origin: 0 100%;
  -ms-animation: spin 3.45s linear infinite;
  border-top: 10px solid #1bbce8;
  border-right: 10px solid #1bbce8;
  border-top-right-radius: 100%;
  margin-top: -3px;
  margin-left: 0;
  animation: 3.45s linear infinite spin;
  position: absolute;
  top: 0;
  left: 50%;
}

.cssload-progress.cssload-float .cssload-progress-item:before {
  height: 49px;
  border-top-width: 2px;
  margin-top: 0;
}

.cssload-progress.cssload-float.cssload-shadow:before {
  height: 49px;
  border-top-width: 2px;
  margin-top: -40px;
}

.cssload-progress.cssload-shadow:before {
  content: "";
  width: 44px;
  height: 44px;
  z-index: -1;
  transform-origin: 0 100%;
  -ms-animation: spin 3.45s linear infinite;
  border-top: 10px solid #ddd;
  border-right: 10px solid #ddd;
  border-top-right-radius: 100%;
  margin: -42px 0 0 12px;
  animation: 3.45s linear infinite spin;
  position: absolute;
  top: 50%;
  left: 50%;
}

.cssload-progress.cssload-shadow:after {
  content: "";
  width: 97px;
  height: 97px;
  color: #ddd;
  text-align: center;
  z-index: -1;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  transform-origin: center;
  border: 2px solid #ddd;
  border-radius: 50%;
  margin: -39px 0 0 -39px;
  line-height: 97px;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
  }
}



/*# sourceMappingURL=index.23fc12c5.css.map */
